import styles from "./styles/Spinner.module.css";

export default function Spinner({ blue = true }) {
  const spinnerStyle = blue
    ? styles.spinner + " " + styles.blue
    : styles.spinner;

  return (
    <div className={styles.cont}>
      <div className={spinnerStyle}>
        <div className={styles.bounce1}></div>
        <div className={styles.bounce2}></div>
        <div className={styles.bounce3}></div>
      </div>
    </div>
  );
}
