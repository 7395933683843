export function convertMoves(moves) {
  const convertedMoves = {};
  moves.forEach((move) => {
    if (convertedMoves[move.from]) {
      convertedMoves[move.from].push(move.to);
    } else {
      convertedMoves[move.from] = [move.to];
    }
  });
  return convertedMoves;
}
