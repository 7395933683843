import React, { useState, useEffect } from "react";
import useTranslation from "next-translate/useTranslation";
import Board from "../../chessBoard/components/Board";

import {
  validateUserMove,
  usePuzzle,
  initPuzzles,
  clearFen,
} from "../../ducks/puzzle";

import { api } from "@/src/services/api";
import { useRouter } from "next/router";
import Link from "next/link";
import { useSettings } from "@/src/ducks/settings";

function getPuzzle(id = null) {
  const puzzleId = id ? "/" + id : "";
  api.get("/puzzles" + puzzleId).then((response) => {
    const { puzzles, nextPuzzleId } = response.data;

    initPuzzles({ puzzles, nextPuzzleId });
  });
}

export default function FrontPagePuzzle() {
  const { t: homeT } = useTranslation("home");

  const router = useRouter();
  const { id } = router.query;

  const [boardSize, setBoardSize] = useState(null);

  const settings = useSettings();

  useEffect(() => {
    getPuzzle(id);
  }, [id]);

  const {
    fen,
    playingWhite,
    puzzlesDone,
    highLightPos,
    possibleMoves,
    animate,
    lastMove,
    moves,
    nextPuzzleId,
  } = usePuzzle();

  useEffect(() => {
    function updateChessboardSize() {
      if (window.innerWidth >= 768) {
        setBoardSize(375);
      } else {
        setBoardSize(window.innerWidth - 70);
      }
    }

    window.addEventListener("resize", updateChessboardSize);
    window.addEventListener("orientationchange", updateChessboardSize);

    updateChessboardSize();

    return () => {
      window.removeEventListener("resize", updateChessboardSize);
      window.removeEventListener("orientationchange", updateChessboardSize);
      clearFen();
    };
  }, []);

  const getLastMove = (moves) => {
    if (moves != null) {
      const length = moves.length;

      return moves[length - 1];
    }
    return null;
  };

  const playingColorText = playingWhite
    ? homeT("puzzle_white")
    : homeT("puzzle_black");

  return (
    <div>
      <div className=" my-10 max-w-[1012px] flex justify-center mx-auto">
        <div className="flex flex-col items-center justify-between lg:flex-row lg:space-x-10">
          <div className="mb-5 space-y-5 lg:pr-8 text-center lg:text-left lg:mb-0">
            <h2 className="font-semibold ">{homeT("puzzle_title")}</h2>
            <p className="max-w-md text-lg">{homeT("puzzle_text")}</p>
            <div className="pt-3">
              <Link href={`/puzzle/${nextPuzzleId}`}>
                <button className="text-white btn py-4 px-8 lg:p-0 lg:btn-base hover:opacity-70 bg-altblue-700 ">
                  {homeT("next_puzzle")}
                </button>
              </Link>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col">
              <div className="p-3 text-center ">
                <p>{playingColorText}</p>
              </div>
              <div>
                <span id="board">
                  {moves ? (
                    <Board
                      fen={fen}
                      lastMove={lastMove}
                      animate={animate}
                      size={boardSize}
                      playingWhite={playingWhite}
                      onMoveMade={validateUserMove}
                      highLightKey={highLightPos}
                      possibleMoves={possibleMoves}
                      settings={settings}
                      lastPuzzleMove={getLastMove(moves)}
                      puzzleDone={puzzlesDone[0]}
                    />
                  ) : (
                    <Board
                      fen={fen}
                      lastMove={lastMove}
                      animate={animate}
                      size={boardSize}
                      playingWhite={playingWhite}
                      onMoveMade={validateUserMove}
                      highLightKey={highLightPos}
                      possibleMoves={possibleMoves}
                      settings={settings}
                      lastPuzzleMove={{ from: "e5", to: "c6" }}
                      puzzleDone={puzzlesDone[0]}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
