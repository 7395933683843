import { createReduxModule } from "hooks-for-redux";
import { convertMoves } from "../util/moves";
import { api } from "../services/api";
import { Chess } from "@chesshotel/chesslib";

const initialState = {
  puzzlesDone: [false, false, false],
  list: null,
  level: 0,
  moves: null,
  moveIndex: 0,
  highLightPos: null,
  fen: null,
  possibleMoves: {},
  playingWhite: true,
  animate: false,
  lastMove: null,
  nextPuzzleId: null,
  id: null,
};

const [
  usePuzzle,
  {
    setPuzzles,
    setCurrentPuzzle,
    updatePosition,
    setMove,
    setPuzzleDone,
    setHint,
    clearFen,
  },
  puzzleStore,
] = createReduxModule("puzzle", initialState, {
  setPuzzles: (
    state,
    { list, nextPuzzleId, puzzle, initFen, playingWhite, id }
  ) => {
    return {
      ...state,
      list,
      puzzlesDone: [false, false, false],
      nextPuzzleId,
      fen: initFen,
      moves: puzzle.moves,
      level: 0,
      playingWhite,
      animate: false,
      highLightPos: null,
      id,
    };
  },
  setCurrentPuzzle: (state, { fen, moves, level, playingWhite }) => ({
    ...state,
    fen,
    moves,
    moveIndex: 0,
    level,
    playingWhite,
    highLightPos: null,
    animate: false,
  }),
  setMove: (state, lastMove) => ({
    ...state,
    fen: lastMove.fen,
    lastMove,
  }),
  updatePosition: (
    state,
    { moveIndex, fen, possibleMoves, lastMove, animate = true }
  ) => ({
    ...state,
    moveIndex,
    fen,
    possibleMoves,
    highLightPos: state.moveIndex !== moveIndex ? null : state.highLightPos,
    animate,
    lastMove,
  }),
  setPuzzleDone: (state, level) => ({
    ...state,
    puzzlesDone: state.puzzlesDone.map((item, index) => {
      return index === level ? true : item;
    }),
    highLightPos: null,
    possibleMoves: {},
  }),
  setHint: (state) => {
    const moves = state.moves;
    const moveIndex = state.moveIndex;
    const pos = moves[moveIndex].from;

    return {
      ...state,
      highLightPos: pos,
    };
  },
  clearFen: (state) => ({ ...state, fen: null }),
});

export {
  usePuzzle,
  setPuzzles,
  setCurrentPuzzle,
  updatePosition,
  setPuzzleDone,
  setHint,
  clearFen,
  puzzleStore,
};

let chess = null;

let timeOut;

export function initPuzzles({ puzzles, nextPuzzleId }) {
  const list = puzzles.map((puzzle) => {
    puzzle.moves = puzzle.moves.map((move) => {
      if (move.promo) {
        move.promotion = move.promo.toLowerCase();
        delete move.promo;
      }
      return move;
    });
    return puzzle;
  });

  const puzzle = list[0];
  const { initFen, fen, playingWhite, possibleMoves, firstMove } =
    getGameData(puzzle);

  setPuzzles({
    list,
    puzzle,
    initFen,
    playingWhite,
    nextPuzzleId,
    id: puzzle.id,
  });
  clearTimeout(timeOut);
  timeOut = setTimeout(() => {
    updatePosition({ fen, moveIndex: 1, possibleMoves, lastMove: firstMove });
  }, 700);
}

function getGameData(puzzle) {
  chess = Chess(puzzle.fen);
  const initFen = puzzle.fen;

  // Prepare first move
  const firstMove = puzzle.moves[0];
  chess.move(firstMove);
  const fen = chess.fen();
  const playingWhite = chess.turn() === "w";
  const possibleMoves = convertMoves(chess.moves({ verbose: true }));
  return { initFen, fen, playingWhite, possibleMoves, firstMove };
}

export function choosePuzzleAtIndex(index: number) {
  const puzzle = puzzleStore.getState().list[index];

  const { initFen, fen, playingWhite, possibleMoves, firstMove } =
    getGameData(puzzle);

  // Set init pos
  setCurrentPuzzle({
    fen: initFen,
    moves: puzzle.moves,
    level: index,
    playingWhite,
  });

  // Make first move
  clearTimeout(timeOut);
  timeOut = setTimeout(() => {
    updatePosition({ fen, moveIndex: 1, possibleMoves, lastMove: firstMove });
  }, 700);
}

export function validateUserMove(move) {
  const { moveIndex, moves, possibleMoves, level, fen, lastMove } =
    puzzleStore.getState();

  setMove(move);

  const correctMove = moves[moveIndex];

  if (
    move.from === correctMove.from &&
    move.to === correctMove.to &&
    move.promotion === correctMove.promotion
  ) {
    if (moves.length === moveIndex + 1) {
      setPuzzleDone(level);
      updatePosition({
        fen: move.fen,
        moveIndex: moveIndex,
        possibleMoves: {},
        lastMove: move,
      });
    } else {
      const nextMove = moves[moveIndex + 1];
      chess.move(move);

      chess.move(nextMove);
      const fen = chess.fen();
      const nextPossibleMoves = convertMoves(chess.moves({ verbose: true }));
      setTimeout(() => {
        updatePosition({
          fen,
          moveIndex: moveIndex + 2,
          possibleMoves: nextPossibleMoves,
          lastMove: nextMove,
        });
      }, 100);
    }
  } else {
    updatePosition({
      fen: move.fen,
      moveIndex,
      possibleMoves,
      animate: true,
      lastMove: move,
    });
    setTimeout(() => {
      updatePosition({ fen, moveIndex, possibleMoves, lastMove });
    }, 100);
  }
}

export async function getPuzzle() {
  try {
    if (puzzleStore.getState().list == null) {
      const response = await api.get("/puzzles");
      setPuzzles(response.data.puzzles);
    }
  } catch (e) {
    console.log("error in setPuzzles");
  }
}
