// const badWords = [
//   "anal",
//   "anus",
//   "arrse",
//   "arse",
//   "ass",
//   "ass-fucker",
//   "asses",
//   "assfucker",
//   "assfukka",
//   "asshole",
//   "assholes",
//   "asswhole",
//   "a_s_s",
//   "b!tch",
//   "b00bs",
//   "ballbag",
//   "balls",
//   "ballsack",
//   "bastard",
//   "beastial",
//   "beastiality",
//   "bellend",
//   "bestial",
//   "bestiality",
//   "bi+ch",
//   "biatch",
//   "bitch",
//   "bitcher",
//   "bitchers",
//   "bitches",
//   "bitchin",
//   "bitching",
//   "bloody",
//   "blow job",
//   "blowjob",
//   "blowjobs",
//   "boiolas",
//   "bollock",
//   "bollok",
//   "boner",
//   "boob",
//   "boobs",
//   "booobs",
//   "boooobs",
//   "booooobs",
//   "booooooobs",
//   "breasts",
//   "buceta",
//   "bugger",
//   "bum",
//   "bunny fucker",
//   "butt",
//   "butthole",
//   "buttmuch",
//   "buttplug",
//   "c0ck",
//   "c0cksucker",
//   "carpet muncher",
//   "cawk",
//   "chink",
//   "cipa",
//   "cl1t",
//   "clit",
//   "clitoris",
//   "clits",
//   "cnut",
//   "cock",
//   "cock-sucker",
//   "cockface",
//   "cockhead",
//   "cockmunch",
//   "cockmuncher",
//   "cocks",
//   "cocksuck",
//   "cocksucked",
//   "cocksucker",
//   "cocksucking",
//   "cocksucks",
//   "cocksuka",
//   "cocksukka",
//   "cok",
//   "cokmuncher",
//   "coksucka",
//   "coon",
//   "cox",
//   "crap",
//   "cum",
//   "cummer",
//   "cumming",
//   "cums",
//   "cumshot",
//   "cunilingus",
//   "cunillingus",
//   "cunnilingus",
//   "cunt",
//   "cuntlick ",
//   "cuntlicker ",
//   "cuntlicking",
//   "cunts",
//   "cyalis",
//   "cyberfuc",
//   "cyberfuck",
//   "cyberfucked ",
//   "cyberfucker",
//   "cyberfuckers",
//   "cyberfucking",
//   "d1ck",
//   "damn",
//   "dick",
//   "dickhead",
//   "dildo",
//   "dildos",
//   "dink",
//   "dinks",
//   "dirsa",
//   "dlck",
//   "dog-fucker",
//   "doggin",
//   "dogging",
//   "donkeyribber",
//   "doosh",
//   "duche",
//   "dyke",
//   "ejaculate",
//   "ejaculated",
//   "ejaculates",
//   "ejaculating",
//   "ejaculatings",
//   "ejaculation",
//   "ejakulate",
//   "f u c k",
//   "f u c k e r",
//   "fanny",
//   "fag",
//   "fagging",
//   "faggitt",
//   "faggot",
//   "faggs",
//   "fagot",
//   "fagots",
//   "fags",
//   "fanny",
//   "fannyflaps",
//   "fannyfucker",
//   "fanyy",
//   "fatass",
//   "fcuk",
//   "fcuker",
//   "fcuking",
//   "feck",
//   "fecker",
//   "felching",
//   "fellate",
//   "fellatio",
//   "fingerfuck",
//   "fingerfucked",
//   "fingerfucker",
//   "fingerfuckers",
//   "fingerfucking",
//   "fingerfucks",
//   "fistfuck",
//   "fistfucked",
//   "fistfucker",
//   "fistfuckers",
//   "fistfucking",
//   "fistfuckings",
//   "fistfucks",
//   "flange",
//   "fook",
//   "fooker",
//   "fuck",
//   "fucka",
//   "fucked",
//   "fucker",
//   "fuckers",
//   "fuckhead",
//   "fuckheads",
//   "fuckin",
//   "fucking",
//   "fuckings",
//   "fuckingshitmotherfucker",
//   "fuckme",
//   "fucks",
//   "fuckwhit",
//   "fuckwit",
//   "fudge packer",
//   "fudgepacker",
//   "fuk",
//   "fuker",
//   "fukker",
//   "fukkin",
//   "fuks",
//   "fukwhit",
//   "fukwit",
//   "fux",
//   "f_u_c_k",
//   "gangbang",
//   "gangbanged",
//   "gangbangs",
//   "gaylord",
//   "gaysex",
//   "goatse",
//   "God",
//   "god-dam",
//   "god-damned",
//   "goddamn",
//   "goddamned",
//   "hardcoresex",
//   "hell",
//   "heshe",
//   "hoar",
//   "hoare",
//   "hoer",
//   "homo",
//   "hore",
//   "horniest",
//   "horny",
//   "hotsex",
//   "jack-off",
//   "jackoff",
//   "jap",
//   "jerk-off",
//   "jism",
//   "jiz",
//   "jizm",
//   "jizz",
//   "kawk",
//   "kkk",
//   "knob",
//   "knobead",
//   "knobed",
//   "knobend",
//   "knobhead",
//   "knobjocky",
//   "knobjokey",
//   "kock",
//   "kondum",
//   "kondums",
//   "kum",
//   "kummer",
//   "kumming",
//   "kums",
//   "kunilingus",
//   "l3i+ch",
//   "l3itch",
//   "labia",
//   "masochist",
//   "master-bate",
//   "masterb8",
//   "masterbat*",
//   "masterbate",
//   "masterbation",
//   "masterbations",
//   "masturbate",
//   "mo-fo",
//   "mofo",
//   "mothafuck",
//   "mothafucka",
//   "mothafuckas",
//   "mothafuckaz",
//   "mothafucked",
//   "mothafucker",
//   "mothafuckers",
//   "mothafuckin",
//   "mothafucking",
//   "mothafuckings",
//   "mothafucks",
//   "mother fucker",
//   "motherfuck",
//   "motherfucked",
//   "motherfucker",
//   "motherfuckers",
//   "motherfuckin",
//   "motherfucking",
//   "motherfuckings",
//   "motherfuckka",
//   "motherfucks",
//   "muff",
//   "mutha",
//   "muthafecker",
//   "muthafuckker",
//   "muther",
//   "mutherfucker",
//   "nazi",
//   "nigga",
//   "niggah",
//   "niggas",
//   "niggaz",
//   "nigger",
//   "niggers",
//   "nob",
//   "nob jokey",
//   "nobhead",
//   "nobjocky",
//   "nobjokey",
//   "numbnuts",
//   "nutsack",
//   "orgasim",
//   "orgasims",
//   "orgasm",
//   "orgasms",
//   "p0rn",
//   "pawn",
//   "pecker",
//   "penis",
//   "penisfucker",
//   "phonesex",
//   "phuck",
//   "phuk",
//   "phuked",
//   "phuking",
//   "phukked",
//   "phukking",
//   "phuks",
//   "phuq",
//   "pigfucker",
//   "pimpis",
//   "piss",
//   "pissed",
//   "pisser",
//   "pissers",
//   "pisses",
//   "pissflaps",
//   "pissin",
//   "pissing",
//   "pissoff",
//   "poop",
//   "porn",
//   "porno",
//   "pornography",
//   "pornos",
//   "prick",
//   "pricks",
//   "pron",
//   "pube",
//   "pusse",
//   "pussi",
//   "pussies",
//   "pussy",
//   "pussys",
//   "rectum",
//   "retard",
//   "rimjaw",
//   "rimming",
//   "s hit",
//   "s.o.b.",
//   "sadist",
//   "schlong",
//   "screwing",
//   "scroat",
//   "scrote",
//   "scrotum",
//   "semen",
//   "sex",
//   "sh!+",
//   "sh!t",
//   "sh1t",
//   "shag",
//   "shagger",
//   "shaggin",
//   "shagging",
//   "shemale",
//   "shi+",
//   "shit",
//   "shitdick",
//   "shite",
//   "shited",
//   "shitey",
//   "shitfuck",
//   "shitfull",
//   "shithead",
//   "shiting",
//   "shitings",
//   "shits",
//   "shitted",
//   "shitter",
//   "shitters",
//   "shitting",
//   "shittings",
//   "shitty",
//   "skank",
//   "slut",
//   "sluts",
//   "smegma",
//   "smut",
//   "snatch",
//   "son-of-a-bitch",
//   "spac",
//   "spunk",
//   "s_h_i_t",
//   "t1tties",
//   "teets",
//   "teez",
//   "testical",
//   "testicle",
//   "tit",
//   "titfuck",
//   "tits",
//   "titt",
//   "tittiefucker",
//   "titties",
//   "tittyfuck",
//   "tittywank",
//   "titwank",
//   "tosser",
//   "turd",
//   "twat",
//   "twat",
//   "twathead",
//   "twatty",
//   "twunt",
//   "twunter",
//   "vagina",
//   "viagra",
//   "vulva",
//   "wang",
//   "wank",
//   "wanker",
//   "wanky",
//   "whoar",
//   "whore",
//   "willies",
//   "willy",
//   "xrated",
//   "xxx",
//   "hitler",
//   "heil hitler",
//   "suck my dick",
//   "suck dick",
// ];

const containing = ["fuck", "my dick"];

const startsWith = [
  "sexchess",
  "judishes",
  "chessex",
  "chesssex",
  "kill ",
  "snoppen",
  "pillen",
  "fjomp",
  "blottarn",
  "picken",
  "pillesnopp",
  "putineatsshit",
];

const charReplacements = [
  ["@", "a"],
  ["0", "o"],
  ["1", "i"],
  ["2", "r"],
  ["3", "e"],
  ["4", "a"],
  ["5", "s"],
  ["7", "t"],
  ["8", "b"],
  ["9", "g"],
  ["|<", "k"],
  ["|/|", "m"],
  ["||", "n"],
  ["ä", "a"],
  ["ã", "a"],
  ["â", "a"],
  ["ä", "a"],
  ["á", "a"],
  ["à", "a"],
  ["å", "a"],
  ["é", "e"],
  ["è", "e"],
  ["ë", "e"],
  ["ê", "e"],
  ["§", "s"],
  ["$", "s"],
  ["£", "l"],
  ["€", "e"],
  ["ü", "u"],
  ["û", "u"],
  ["ú", "u"],
  ["ù", "u"],
  ["î", "i"],
  ["ï", "i"],
  ["í", "i"],
  ["ì", "i"],
  ["ÿ", "y"],
  ["ý", "y"],
  ["ö", "o"],
  ["ô", "o"],
  ["õ", "o"],
  ["ó", "o"],
  ["ò", "o"],
  ["(.)", "boob"],
];

export function hasBadWord(text) {
  text = text.toLowerCase();

  for (let i = 0; i < charReplacements.length; i++) {
    const ra = charReplacements[i];
    text = text.replace(ra[0], ra[1]);
  }

  // const words = text.split(" ");

  // for (let j = 0; j < words.length; j++) {
  //   const word = words[j];
  //   for (let i = 0; i < badWords.length; i++) {
  //     const w = badWords[i];
  //     if (word == w) {
  //       return true;
  //     }
  //   }
  // }

  for (let i = 0; i < containing.length; i++) {
    const w = containing[i];
    if (text.indexOf(w) !== -1) {
      return true;
    }
  }

  for (let i = 0; i < startsWith.length; i++) {
    const w = startsWith[i];
    if (text.startsWith(w)) {
      return true;
    }
  }

  return false;
}
